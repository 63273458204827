<template>
    <el-select
        ref="managerDropdown"
        v-model="selectedManager"
        :placeholder="$t('common.manager')"
        filterable
        clearable
        :multiple="multiple"
        :class="customClass"
        :collapse-tags="collapseTags"
        :filter-method="filterMethod"
        @visible-change="resetData"
    >
        <el-option
            v-for="manager in managersData"
            :key="manager.uuid"
            :label="manager.name + ' ' + manager.surname"
            :value="manager.uuid"
        />
    </el-select>
</template>
<script>
import Vue from 'vue';
import { Select, Option } from 'element-ui';
import queryString from 'query-string';
import filter from 'lodash/filter';

Vue.use(Select);
Vue.use(Option);

export default {
    props: {
        multiple: {
            type:    Boolean,
            default: false,
        },
        customClass: {
            type:    String,
            default: '',
        },
        collapseTags: {
            type:    Boolean,
            default: true,
        },
        inView: {
            type:    String,
            default: null,
        },
        cookieChoice: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectedManager:    '',
            managersData:       [],
            managersDataOrigin: [],
        };
    },

    computed: {
        managersDataVuex() {
            return this.$store.state.managersDropdown;
        },
    },

    watch: {
        managersDataVuex() {
            this.assignData();
        },

        selectedManager() {
            this.$router.replace({
                query: { manager: this.selectedManager },
            }).catch(() => {});

            if (this.cookieChoice) {
                window.Cookies.set(`managers_in_view_${this.inView}`, this.selectedManager, { expires: 365 });
            }

            this.$emit('selected', this.selectedManager);
        },
    },

    created() {
        this.assignData();

        const cookieManager = window.Cookies.get(`managers_in_view_${this.inView}`);
        let { manager } = queryString.parse(window.location.search, { sort: false });

        if (!manager && !this.multiple && cookieManager) {
            this.selectedManager = cookieManager;
            return;
        }

        if (this.multiple) {
            manager = Array.isArray(manager) ? manager : (manager ? [manager] : []);
            this.selectedManager = manager;
            return;
        }

        this.selectedManager = manager || '';
    },

    methods: {
        assignData() {
            this.managersData = { ...this.managersDataVuex };
            this.managersDataOrigin = { ...this.managersDataVuex };
        },

        resetData() {
            this.managersData = this.managersDataOrigin;
        },

        filterMethod(query) {
            this.managersData = filter(this.managersDataOrigin, manager => (
                manager.name.toLowerCase().includes(query.toLowerCase())
                || (manager.surname && manager.surname.toLowerCase().includes(query.toLowerCase()))
            ));
        },
    },
};
</script>
<style>
    .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
        left: 3px;
    }
</style>
